.search-button {
  margin-top: 1.7em;
}

.pay-button {
  margin-left: 1em;
  margin-top: -.5em;
}

.form-default {
  color: #BBBBBB;
}

.panel {
  width: 85%;
  margin-top: 2em;
  margin-left: 0;
  margin-bottom: 1em;
}

.panel-body {
  width: 80%;
  margin-left: 1em;
  margin-top: 1em;
  margin-right: 1em;
  margin-bottom: 1em;
}

.helper-text {
  color: #e93143;
  font-size: 12px;
  margin-top: 5px;
  height: 12px;
  display: block;
}

.helper-text-green {
  color: green;
}

/* Hosted Payment Fields styles*/
.hosted-field-focus { 
  border: 1px solid #66afe9;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
}

.hosted-field-invalid {
  border: 1px solid #e93143;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(233,49,67, 0.8);
}

.hosted-field-valid {
  border: 1px solid #14ba57 ;
}